export const texts = {
    DE: {
        sidePanel: ["Biografie", "Ausstellungen", "Kataloge", "Publikationen", "Videos", "Impressum",],
        Ausstellungen: [
                {
                    mainTitle: "Josephine Baker",
                    title: "Icon In Motion",
                    date: "26. Januar – 28. April 2024", 
                    description: "Neue Nationalgalerie, Berlin. Kuratiert von: Klaus Biesenbach, Terri Francis, Mona Horncastle, Kandis Williams",
                },
                {
                    mainTitle: "Vom Krafttier zum Angsttier?",
                    title: "Kulturgeschichte des Wolfes",
                    date: "13. Dezember 2023 - 1. September 2024",
                    description: "Schlossmuseum, Linz. Kuratiert von: Mona Horncastle",
                    imgUrl: "WolfPoster.jpg",
                },
                {
                    mainTitle: "Josephine Baker",
                    title: "Freiheit, Gleichheit, Menschlichkeit",
                    date: "18. Mai - 24. September 2023",
                    description: "Kunst- und Ausstellungshalle der Bundesrepublik Deutschland, Bonn. Kuratiert von: Katherina Chrubasik, Mona Horncastle",
                    imgUrl: "BakerBonn.jpg",
                },
                {
                    mainTitle: "DAS WIR IM ICH",
                    title: "Vom Bauernkrieg zum Bilderkosmos",
                    date: "Ausstellung Communale Oberösterreich vom 2. Juli - 26. Oktober 2022",
                    description: "Schloss Starhemberg, Eferding. Kuratiert von: Mona Horncastle, Alfred Weidinger"
                },
                {
                    mainTitle: "Anna Heindl",
                    title: "Longtime Love Affairs",
                    date: "07. Oktober 2020 - 10. Januar 2021",
                    description: "Schlossmuseum Linz. Kuratiert von: Mona Horncastle",
                    imgUrl: "HeindlKatalog.jpg",
                },
                {
                    mainTitle: "Shunga & Klimt",
                    title: "Explizit Erotisches aus Japan und Wien",
                    date: "19. März - 19. Juni 2016",
                    description: "Buchheim Museum, Bernried. Kuratiert von: Mona Horncastle",
                    imgUrl: "KlimtShunga.jpg",
                }
            ],
        Kataloge: [
            {
                mainTitle: "Josephine Baker",
                title: "Freiheit. Gleichheit. Menschlichkeit", 
                description: "Katalog zur Ausstellung in der Kunst- und Ausstellungshalle der Bundesrepublik. Deutschland, Bonn 2023, Distanz Verlag, ISBN 978-3-95476-577-5",
                imgUrl: "BakerKatalog.jpg",
            },
            {
                mainTitle: "Gerhard Haderer",
                title: "Kosmos", 
                description: "Katalog zur Ausstellung im Schlossmuseum, Linz 2022,   ISBN 978-3-85474-380-4",
                concept: "Text, Konzept, Projektmanagement: Mona Horncastle",
                publisher: "Herausgeber: OÖ Landes-Kultur GmbH",
                imgUrl: "Haderer.jpg",
            },
            {
                mainTitle: "Anna Heindl",
                title: "Longtime Love Affairs", 
                description: "Katalog zur Ausstellung im Schlossmuseum, Linz 2021, ISBN 978-3-85474-359-0",
                concept: "Text, Konzept: Mona Horncastle",
                publisher: "Herausgeber: OÖ Landes-Kultur GmbH",
                translator: "Übersetzungen vom Deutschen ins Englische von Mina Jaff",
                imgUrl: "Heindl.jpeg",
            },
            {
                mainTitle: "Parov Stellar",
                title: "I‘ll be OK soon", 
                description: "Katalog zur Ausstellung im Francisco Carolinum, Linz 2021, ISBN 978-3-85474-376-7",
                concept: "Text, Konzept, Projektmanagement: Mona Horncastle",
                publisher: "Herausgeber: OÖ Landes-Kultur GmbH",
                imgUrl: 'Stelar.jpg',
            },
            {
                mainTitle: "Proof of Art",
                title: "A short history of NFTs, from the beginning of digital art to the metaverse", 
                description: "Katalog zur Ausstellung im Francisco Carolinum, Linz 2021, Distanz Verlag, ISBN 978-3-85474-358-3",
                concept: "Projektmanagement: Mona Horncastle",
                publisher: "Herausgeber: OÖ Landes-Kultur GmbH",
                imgUrl: "PoA.jpg",
            },
            {
                mainTitle: "Manfred Wakolbinger",
                title: "Space Is The Place", 
                description: "Katalog zur Ausstellung im Schlossmuseum, Linz 2020, ISBN 978-3-85474- 355-2",
                concept: "Text, Konzept, Projektmanagement: Mona Horncastle",
                publisher: "Herausgeber: OÖ Landes-Kultur GmbH",
                translator: "Übersetzungen vom Deutschen ins Englische von Mina Jaff",
                imgUrl: "Wakolbinger.jpg",
            },
        ],
        Publikationen: [
            {
                author: "Mona Horncastle",
                mainTitle: "Josephine Baker",
                title: "Weltstar. Freiheitskämpferin. Ikone.",
                description: "Mit einem Nachwort von Annette Dorgerloh",
                publisher: "Molden, Wien 2020, ISBN 978-3-222-15046-3",
                imgUrl: "Baker.png",
                reviews: [{review: "Josephine Baker ist ein mitreißendes Buch geworden […]. Ein verrücktes Stück Zeitgeschichte.", reviewer: "Susan Vahabzadeh, SÜDDEUTSCHE ZEITUNG"}, {review: "Was Horncastles Buch besonders lesenswert macht, ist ihre gute Kenntnis des historischen Hintergrunds [...].", reviewer: "Rose-Maria Gropp, FRANKFURTER ALLGEMEINE ZEITUNG"}],
            },
            {
                author: "Mona Horncastle",
                mainTitle: "Margarete Schütte-Lihotzky",
                title: "Architektin. Widerstandskämpferin. Aktivistin.",
                description: "Mit einem Nachwort von Uta Graff",
                publisher: "Molden, Wien 2019, ISBN 978-3-222-15036-4[17]",
                imgUrl: "Margarete.jpg",
                reviews: [{review: "Eine neue Biografie, die alle Lebensaspekte der in Österreich lang ignorierten Architektin erfasst, alle Wege und Irrwege, war längst fällig. Mona Horncastle hat sie nun vorgelegt.", reviewer: "Günther Haller, DIE PRESSE"}],
            },
            {
                author: "Mona Horncastle & Alfred Weidinger",
                mainTitle: "Gustav Klimt",
                title: "Die Biografie",
                publisher: "Brandstätter, Wien 2018, ISBN 978-3-7106-0192-7",
                imgUrl: "Klimt.jpeg",
                reviews: [{review: "Die Autoren räumen mit hoher Qualität endlich mit vielen Halbwahrheiten auf.", reviewer: "Klimt Verein"}, {review: "Es ist die Stärke von Die Biografie, überzeugende Einblicke in Klimts Schafffen du seine künstlerische Entwicklung zu geben.", reviewer: "Katharina Rudolph, FRANKFURTER ALLGEMEINE ZEITUNG"}, {review: "Auch ohne Legenden liest sich dieses Buch wirklich packend.", reviewer: "Andrea Gerk, DLF LESART"}],
            },
            {
                author: "Mona Horncastle & Alfred Weidinger",
                mainTitle: "Klimts Frauenbilder",
                title: "Das Weib. Das Ornament. Das Sexualobjekt",
                publisher: "Belvedere, Wien 2016, ISBN 978-3-86228-132-9",
                imgUrl: "KlimtFrauen.jpg",
            },
            {
                author: "Mona Horncastle & Matthias Lehmann",
                mainTitle: "Claude Monet",
                title: "Kunst-Comic",
                publisher: "Prestel, München 2012, ISBN 978-3791370934",
                imgUrl: "MonetComic.jpg",
            },
            {
                author: "Mona Horncastle & Vitali P. Konstantinov",
                mainTitle: "Gustav Klimt",
                title: "Kunst-Comic",
                publisher: "Prestel, München 2012, ISBN 978-3791370927",
                imgUrl: "KlimtComic.jpg",
            },
            {
                author: "Mona Horncastle & Barbara Yelin",
                mainTitle: "Vincent van Gogh",
                title: "Kunst-Comic",
                publisher: "Prestel, München 2011, ISBN 978-3791370712",
                imgUrl: "VanGoghComic.jpg",
            },
            {
                author: "Mona Horncastle & Barbara Yelin",
                mainTitle: "Albrecht Dürer",
                title: "Kunst-Comic",
                publisher: "Prestel, München 2011, ISBN 978-3791370705",
                imgUrl: "DuererComic.jpg",
            },
        ],
        Impressum: [
            {
                name: "Mona Horncastle",
                address: "Viktoriastr. 25, 80803 München",
                phone: "+49 170 7753075",
                email: "mh@mona-horncastle.de",
                details: ["Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 6 Abs.1 MDStV und § 8 Abs.1 TDG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.", "Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers."],
            }
        ],
    },
    EN: {
        sidePanel: ["Biography", "Exhibitions", "Catalogues", "Publications", "Videos", "Privacy Policy"],
        Exhibitions: [
            {
                mainTitle: "Josephine Baker",
                title: "Icon In Motion",
                date: "January 26 – April 28, 2024", 
                description: "Neue Nationalgalerie, Berlin. Curated by: Klaus Biesenbach, Terri Francis, Mona Horncastle, Kandis Williams",
            },
            {
                mainTitle: "Vom Krafttier zum Angsttier?",
                title: "Kulturgeschichte des Wolfes",
                date: "December 13, 2023 - September 1, 2024",
                description: "Schlossmuseum, Linz. Curated by: Mona Horncastle",
                imgUrl: "WolfPoster.jpg",
            },
            {
                mainTitle: "Josephine Baker",
                title: "Freiheit, Gleichheit, Menschlichkeit",
                date: "May 18, - September 24, 2023",
                description: "Kunst- und Ausstellungshalle der Bundesrepublik Deutschland, Bonn. Curated by: Katherina Chrubasik, Mona Horncastle",
                imgUrl: "BakerBonn.jpg",
            },
            {
                mainTitle: "DAS WIR IM ICH",
                title: "Vom Bauernkrieg zum Bilderkosmos",
                date: "Ausstellung Communale Oberösterreich from July 2 - October 26, 2022",
                description: "Schloss Starhemberg, Eferding. Curated by: Mona Horncastle, Alfred Weidinger"
            },
            {
                mainTitle: "Anna Heindl",
                title: "Longtime Love Affairs",
                date: "October 7, 2020 - January 10, 2021",
                description: "Schlossmuseum Linz. Curated by: Mona Horncastle",
                imgUrl: "HeindlKatalog.jpg",
            },
            {
                mainTitle: "Shunga & Klimt",
                title: "Explizit Erotisches aus Japan und Wien",
                date: "March 19 - June 19, 2016",
                description: "Buchheim Museum, Bernried. Curated by: Mona Horncastle",
                imgUrl: "KlimtShunga.jpg",
            }
        ],
        Catalogues: [
            {
                mainTitle: "Josephine Baker",
                title: "Freiheit. Gleichheit. Menschlichkeit", 
                description: "Catalogue for the exhibition @ Kunst- und Ausstellungshalle der Bundesrepublik. Germany, Bonn 2023, Distanz Verlag, ISBN 978-3-95476-577-5",
                imgUrl: "BakerKatalog.jpg",
            },
            {
                mainTitle: "Gerhard Haderer",
                title: "Kosmos", 
                description: "Catalogue for the exhibition @ Schlossmuseum, Linz 2022,   ISBN 978-3-85474-380-4",
                concept: "Text, concept, and project management: Mona Horncastle",
                publisher: "Publisher: OÖ Landes-Kultur GmbH",
                imgUrl: "Haderer.jpg",
            },
            {
                mainTitle: "Anna Heindl",
                title: "Longtime Love Affairs", 
                description: "Catalogue for the exhibition @ Schlossmuseum, Linz 2021, ISBN 978-3-85474-359-0",
                concept: "Text and concept: Mona Horncastle",
                publisher: "Publisher: OÖ Landes-Kultur GmbH",
                translator: "Translations from German to English by Mina Jaff",
                imgUrl: "Heindl.jpeg",
            },
            {
                mainTitle: "Parov Stellar",
                title: "I‘ll be OK soon", 
                description: "Catalogue for the exhibition @ Francisco Carolinum, Linz 2021, ISBN 978-3-85474-376-7",
                concept: "Text, concept, and project management: Mona Horncastle",
                publisher: "Publisher: OÖ Landes-Kultur GmbH",
                imgUrl: 'Stelar.jpg',
            },
            {
                mainTitle: "Proof of Art",
                title: "A short history of NFTs, from the beginning of digital art to the metaverse", 
                description: "Catalogue for the exhibition @ Francisco Carolinum, Linz 2021, Distanz Verlag, ISBN 978-3-85474-358-3",
                concept: "Project manager: Mona Horncastle",
                publisher: "Publisher: OÖ Landes-Kultur GmbH",
                imgUrl: "PoA.jpg",
            },
            {
                mainTitle: "Manfred Wakolbinger",
                title: "Space Is The Place", 
                description: "Catalogue for the exhibition @ Schlossmuseum, Linz 2020, ISBN 978-3-85474- 355-2",
                concept: "Text, concept, and project management: Mona Horncastle",
                publisher: "Publisher: OÖ Landes-Kultur GmbH",
                translator: "Translations from German to English by Mina Jaff",
                imgUrl: "Wakolbinger.jpg",
            },
        ],
        Publications: [
            {
                author: "Mona Horncastle",
                artist: "Josephine Baker",
                title: "Weltstar. Freiheitskämpferin. Ikone.",
                description: "With an epilog by Annette Dorgerloh",
                publisher: "Molden, Vienna 2020, ISBN 978-3-222-15046-3",
                imgUrl: "Baker.png",
                reviews: [{review: "Josephine Baker ist ein mitreißendes Buch geworden […]. Ein verrücktes Stück Zeitgeschichte.", reviewer: "Susan Vahabzadeh, SÜDDEUTSCHE ZEITUNG"}, {review: "Was Horncastles Buch besonders lesenswert macht, ist ihre gute Kenntnis des historischen Hintergrunds [...].", reviewer: "Rose-Maria Gropp, FRANKFURTER ALLGEMEINE ZEITUNG"}],
            },
            {
                author: "Mona Horncastle",
                artist: "Margarete Schütte-Lihotzky",
                title: "Architektin. Widerstandskämpferin. Aktivistin.",
                description: "With an epilog by Uta Graff",
                publisher: "Molden, Vienna 2019, ISBN 978-3-222-15036-4",
                imgUrl: "Margarete.jpg",
                reviews: [{review: "Eine neue Biografie, die alle Lebensaspekte der in Österreich lang ignorierten Architektin erfasst, alle Wege und Irrwege, war längst fällig. Mona Horncastle hat sie nun vorgelegt.", reviewer: "Günther Haller, DIE PRESSE"}],
            },
            {
                author: "Mona Horncastle & Alfred Weidinger",
                artist: "Gustav Klimt",
                title: "Die Biografie",
                publisher: "Brandstätter, Vienna 2018, ISBN 978-3-7106-0192-7",
                imgUrl: "Klimt.jpeg",
                reviews: [{review: "Die Autoren räumen mit hoher Qualität endlich mit vielen Halbwahrheiten auf.", reviewer: "Klimt Verein"}, {review: "Es ist die Stärke von Die Biografie, überzeugende Einblicke in Klimts Schafffen du seine künstlerische Entwicklung zu geben.", reviewer: "Katharina Rudolph, FRANKFURTER ALLGEMEINE ZEITUNG"}, {review: "Auch ohne Legenden liest sich dieses Buch wirklich packend.", reviewer: "Andrea Gerk, DLF LESART"}],
            },
            {
                author: "Mona Horncastle & Alfred Weidinger",
                mainTitle: "Klimts Frauenbilder",
                title: "Das Weib. Das Ornament. Das Sexualobjekt",
                publisher: "Belvedere, Vienna 2016, ISBN 978-3-86228-132-9",
                imgUrl: "KlimtFrauen.jpg",
            },
            {
                author: "Mona Horncastle & Matthias Lehmann",
                artist: "Claude Monet",
                title: "Art Comic",
                publisher: "Prestel, Munich 2012, ISBN 978-3791370934",
            },
            {
                author: "Mona Horncastle & Vitali P. Konstantinov",
                artist: "Vincent van Gogh",
                title: "Art Comic",
                publisher: "Prestel, Munich 2012, ISBN 978-3791370927",
            },
            {
                author: "Mona Horncastle & Barbara Yelin",
                artist: "Vincent van Gogh",
                title: "Art Comic",
                publisher: "Prestel, Munich 2011, ISBN 978-3791370712",
            },
            {
                author: "Mona Horncastle & Barbara Yelin",
                artist: "Albrecht Dürer",
                title: "Art Comic",
                publisher: "Prestel, Munich 2011, ISBN 978-3791370705",
            },
        ],
        "Privacy Policy": [
            {
                name: "Mona Horncastle",
                address: "Viktoriastr. 25, 80803 Munich, Germany",
                phone: "+49 170 7753075",
                email: "mh@mona-horncastle.de",
                details: ["The contents of our pages have been created with the utmost care. However, we cannot guarantee the accuracy, completeness, and timeliness of content other than our own. As a service provider, we are responsible for our own content on these pages in accordance with § 6 para. 1 MDStV (German Interstate Agreement on Media Services) and § 8 para. 1 TDG (German Teleservices Act) under general laws. Despite careful content control, we assume no liability for the content of external links. The operators of the linked pages are solely responsible for their content.", "The content and works created by the site operators on these pages are subject to German copyright law. Contributions by third parties are identified as such. Reproduction, editing, distribution, and any kind of exploitation beyond the limits of copyright require the written consent of the respective author or creator."],
            }
        ],
    }
}