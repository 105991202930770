function Biography({ language }) {
    const bioGerman = "Mona Horncastle ist promovierte Kunsthistorikerin. Sie lebt und arbeitet in München und Berlin. Von 2005 bis 2018 war sie Verlegerin des Horncastle Verlages, der auf Kunstbücher für Kinder spezialisiert war. Bis 2022 leitete sie zudem ein gemeinnütziges Unternehmen für Bildungsprojekte in Kooperation mit zahlreichen Museen, wie etwa dem Städel Museum in Frankfurt, dem Lenbachhaus und dem Ägyptischen Museum in München, dem Franz Marc Museum in Kochel, oder auch dem Paula Modersohn-Becker Museum in Bremen. Ihr Engagement galt der Vermittlung von Kunst und Kultur und der Entwicklung museumsdidaktischer Programme. Seit 2016 ist sie als freischaffende Autorin und Kuratorin tätig."
    const bioEnglish = "Mona Horncastle is an Art Historian who earned her doctorate at the Humboldt University of Berlin. She lives and works in Munich and Berlin. From 2005 to 2018, she was the publisher of Horncastle Verlag, specializing in art books for children. Until 2022, she also managed a non-profit organization for educational projects in cooperation with numerous museums such as the Städel Museum in Frankfurt, the Lenbachhaus and the Egyptian Museum in Munich, the Franz Marc Museum in Kochel, and the Paula Modersohn-Becker Museum in Bremen. Her commitment was dedicated to the mediation of art and culture and the development of museum educational programs. Since 2016, she has been working as a freelance author and curator."
    
    return (        
        <>
        <div className="bio-div">
            <img  className="bio-pic" src={`/images/Mona.jpg`} />
            <p className="bio-text">{language === 'DE' ? bioGerman : bioEnglish }
            </p>
        </div>
        </>
    )
}

export default Biography;