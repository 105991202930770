import React, { useEffect } from 'react';
import Item from './Item';

function ItemDisplay({ displayItem, language }) {
    
    useEffect(() => {}, [])
    
    return (
        <div class="main-content">
            <Item displayItem={displayItem} language={language} />
        </div>
    )
};

export default ItemDisplay;