import React from 'react';

function LanguageButton(props){
    const { language, toggleLanguage } = props;

    return(
            <div 
                className="btn-classic"
                onClick={() => toggleLanguage(language)}
            >
                {language}
            </div>
    )
}

export default LanguageButton;