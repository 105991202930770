import React from 'react';
import LanguageButton from './LanguageButton';

function Header(props) {
    const { language, toggleLanguage } = props;
    return (
        <div className="header">
            <h1>Mona Horncastle</h1>
                <LanguageButton language={language} toggleLanguage={toggleLanguage} />
        </div>
    )
}

export default Header;