import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer({ language }) {
    const ref = language === 'DE' ? 'Webseite & Design ' : 'Website & design ';

    return (
        <div className="footer">
            <p>© Copyright 2024</p>
            <div> 
                <a className="hyperlink" href="https://www.linkedin.com/in/mina-jaff-b107b2212/">
                    {ref}
                    <LinkedInIcon/>
                    Mina Jaff
                </a>
            </div>
        </div>
    )
}

export default Footer;