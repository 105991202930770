import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useState } from 'react';
import './App.css';
import Footer from './Footer';
import Header from './Header';
import ItemDisplay from './ItemDisplay';
import ResponsiveDrawer from './ResponsiveDrawer';

const theme = createTheme({
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          fontWeight: 100,
          fontFamily: 'Open Sans, sans-serif',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontWeight: 100,
          fontFamily: 'Open Sans, sans-serif',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          fontWeight: 100,
          fontFamily: 'Open Sans, sans-serif',
        },
      },
    },
  },
});

function App() {
  const [language, setLanguage] = useState('DE');
  const [selectedItem, setSelectedItem] = useState('Biografie');

  const toggleLanguage = () => {
    setLanguage(language === 'EN' ? 'DE' : 'EN');
    setSelectedItem(language === 'DE' ? 'Biography' : 'Biografie');
  }

  const handleSelectItem = (item) => {
    setSelectedItem(item);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Header language={language === 'DE' ? 'EN' : 'DE'} toggleLanguage={toggleLanguage} />
        <ResponsiveDrawer language={language} onSelectItem={handleSelectItem} displayItem={selectedItem} />
        <ItemDisplay displayItem={selectedItem} language={language} />
        <Footer language={language} />
      </div>
    </ThemeProvider>
  );
}

export default App;
