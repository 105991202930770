import React from 'react';

function Videos() {
    const videoIds = ["-kW1LVP4TtQ", "zrmjkqlClKY"];
    return (
    <div className="content-container">
      {videoIds.map((id) => (
        <iframe
        className="item custom-iframe"
        src={`https://www.youtube.com/embed/${id}`}
        title="YouTube Video"
        frameBorder="0"
        allowFullScreen
      ></iframe>
      ))}
    </div>
  );
};

export default Videos;