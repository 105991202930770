import React from 'react';
import { texts } from './languageData';
import Biography from './Biography';
import Videos from './Videos';

function Item({ displayItem, language }) {
    const group = texts[language][displayItem];

    switch (displayItem) {
      case 'Biografie':
        return <Biography language={language} />;
      case 'Biography':
        return <Biography language={language} />;
      case 'Videos':
        return <Videos />;
      default:
        if (Array.isArray(group)) {
            return (
              <>
                {group.map((item, index) => (
                  <div key={index} className="item">
                    {item.mainTitle && <h3 className="title-main">{item.mainTitle}</h3>}
                    <h3 className="title">{item.title}</h3>
                    {item.imgUrl && 
                      <img 
                        src={`/images/${item.imgUrl}`}
                        alt={`${item.title} Image`}
                        className="cover" 
                      />
                    }
                    {item.author && <p className="author">{item.author}</p>}
                    {item.date && <p>{item.date}</p>}
                    {item.description && <p>{item.description}</p>}
                    {item.concept && <p>{item.concept}</p>}
                    {item.translator && <p>{item.translator}</p>}
                    {item.publisher && <p>{item.publisher}</p>}
                    {item.reviews && (
                      <div className="review-section">
                        {item.reviews.map((obj, reviewIndex) => (
                          <div key={reviewIndex} className="review-row">
                            <p className="review">{obj.review}</p>
                            <p className="reviewer">— {obj.reviewer}</p>
                          </div>
                        ))}
                      </div>
                    )}
                    {item.name && <p>{item.name}</p>}
                    {item.address && <p>{item.address}</p>}
                    {item.phone && <p>{item.phone}</p>}
                    {item.email && <p>{item.email}</p>}
                    {item.details && (
                      <div>
                        {item.details.map((p) => (
                          <p className="paragraph">{p}</p>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
            </>
            );
          }
    }
}

export default Item;